// /* eslint-disable react-native/no-color-literals */
// /* eslint-disable react-native/no-inline-styles */
import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { ActivityIndicator, Surface, Text } from 'react-native-paper';
import { useAuth } from 'react-oidc-context';
import { useLocation } from 'react-router-dom';

import { errorMessage } from '@shared/services/errorService';
import { CreditSummary } from '@type/creditSummary.type';

import { useCreditSummary } from '../hooks/creditSummaryHook';

import BureauCards from './BureauCards';

export default function BureauTable() {
  const { getCreditSummary } = useCreditSummary();
  const [loading, setLoading] = useState(true);
  const [bureauSummary, setBureauSummary] = useState<CreditSummary>();
  const [errorMessages, setErrorMessage] = useState('');

  const auth = useAuth();
  const token = auth.user?.access_token || '';

  const location = useLocation();
  const { activeId } = location.state || {};

  const fetchCreditSummary = async () => {
    try {
      const result = await getCreditSummary(activeId, token);
      if ((result as CreditSummary).message) {
        const displayMessage = (result as CreditSummary).message || '';
        setErrorMessage(displayMessage);
      } else {
        setBureauSummary(result as CreditSummary);
      }
      if (
        'status' in (result as AxiosResponse) &&
        (result as AxiosResponse).status !== 200
      ) {
        setErrorMessage(result.message);
      }
    } catch (error) {
      const message = errorMessage(error);
      setErrorMessage(message);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      fetchCreditSummary();
    }, 4000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading ? (
        <ActivityIndicator />
      ) : bureauSummary ? (
        <BureauCards clientData={bureauSummary} />
      ) : (
        <Surface elevation={1} style={styles.parentContainer}>
          <Text variant="titleLarge">{errorMessages}</Text>
        </Surface>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  parentContainer: {
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
    padding: 20,
    width: '100%',
  },
});
